<template>
  <swiper
    :modules="[Autoplay, Pagination, EffectCreative]"
    :autoplay="{
      delay: 3500,
      pauseOnMouseEnter: true,
      disableOnInteraction: false,
    }"
    :pagination="{ type: 'progressbar' }"
    :creative-effect="{
      prev: {
        shadow: true,
        translate: ['-20%', 0, -1],
      },
      next: {
        translate: ['100%', 0, 0],
      },
    }"
    :style="{ '--swiper-pagination-color': '#ed1c24' }"
    :loop="!!items.length"
    effect="creative"
    @swiper="(swiper) => carousel = swiper"
  >
    <q-resize-observer @resize="onResize" />
    <!-- eslint-disable @stylistic/max-len -->
    <div
      v-if="items.length"
      class="q-carousel__control q-carousel__arrow q-carousel__prev-arrow q-carousel__prev-arrow--horizontal absolute flex flex-center z-top gt-sm"
    >
      <q-btn
        :icon="mdiChevronLeft"
        color="primary"
        flat
        round
        dense
        @click="carousel?.slidePrev()"
      />
    </div>
    <div
      v-if="items.length"
      class="q-carousel__control q-carousel__arrow q-carousel__next-arrow q-carousel__next-arrow--horizontal absolute flex flex-center z-top gt-sm"
    >
      <q-btn
        :icon="mdiChevronRight"
        color="primary"
        flat
        round
        dense
        @click="carousel?.slideNext()"
      />
    </div>
    <!-- eslint-enable @stylistic/max-len -->
    <!-- <swiper-slide>
      <q-img
        src="https://static.kygunco.com/cdn-cgi/image/f=auto,h=400/assets/backdrops/facade.jpg"
        placeholder-src="https://static.kygunco.com/cdn-cgi/image/f=auto,h=400/assets/backdrops/facade.jpg"
        srcset="
          https://static.kygunco.com/cdn-cgi/image/f=auto,h=400/assets/backdrops/facade.jpg 1x,
          https://static.kygunco.com/cdn-cgi/image/f=auto,h=800/assets/backdrops/facade.jpg 2x"
        height="400px"
        fit="cover"
        loading="eager"
        fetchpriority="high"
        no-transition
        no-spinner
      >
        <div class="absolute-bottom">
          <div class="text-subtitle2">
            <span class="text-weight-medium">KYGUNCO</span> is your one-stop
            online shop for all things firearms. Headquartered in
            <a
              href="https://www.google.com/maps/dir/?q=place_id:ChIJfwecWCBSaIgRguIjuoPha40"
              target="_blank"
              class="text-link"
            >Bardstown, KY</a>, we offer great deals on all types of firearms,
            <nuxt-link
              class="text-link"
              to="/category/ammunition"
            >ammunition</nuxt-link>,
            <nuxt-link
              class="text-link"
              to="/category/firearm-components"
            >gun parts</nuxt-link>,
            <nuxt-link
              class="text-link"
              to="/category/optics"
            >optics</nuxt-link>, and accessories. We are proud to carry wide
            selections from top brands such as
            <nuxt-link
              class="text-link"
              to="/brand/smith-wesson"
            >Smith & Wesson</nuxt-link>,
            <nuxt-link
              class="text-link"
              to="/brand/taurus"
            >Taurus</nuxt-link>,
            <nuxt-link
              class="text-link"
              to="/brand/springfield-armory"
            >Springfield Armory</nuxt-link>,
            <nuxt-link
              class="text-link"
              to="/brand/glock"
            >Glock</nuxt-link>
            and
            <nuxt-link
              class="text-link"
              to="/brands"
            >
              many more</nuxt-link>. We
            are dedicated to making the online gun buying process safe and
            simple. <span class="text-weight-medium">KYGUNCO</span> has you
            covered with everything you need from concealed carry and home
            defense to hunting and target shooting.
          </div>
        </div>
      </q-img>
    </swiper-slide> -->
    <swiper-slide
      v-for="item in items"
      :key="item.id"
    >
      <StrapiBanner
        :banner="item.attributes"
        :small
      />
    </swiper-slide>
  </swiper>
</template>

<script lang="ts" setup>
import type SwiperClass from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, Pagination, EffectCreative } from 'swiper/modules';
import { mdiChevronLeft, mdiChevronRight } from '@quasar/extras/mdi-v7';
import type { Slider } from '~/types/strapi';

import 'swiper/scss';
import 'swiper/scss/pagination';
import 'swiper/scss/effect-creative';

const { screen } = useQuasar();
const { findOne } = useStrapi();
const carousel = ref<SwiperClass | null>(null);
const small = ref(false);

const { data } = await useAsyncData(
  'home_slider',
  () =>
    findOne<Slider>('sliders', '1', {
      populate: [
        'banners.small',
        'banners.large',
        'banners.content',
        'banners.cta',
      ],
    }),
  {
    immediate: true,
    getCachedData: getCachedPayload,
  },
);

const items = computed(() =>
  data.value?.data.attributes.banners?.data?.filter(e => e.attributes.publishedAt != null) ?? []);

watch(items, () => carousel.value?.update());

const onResize = (size: { width: number; height: number }) =>
  small.value = size.width < screen.sizes.md;

// onNuxtReady(() => execute());
</script>
