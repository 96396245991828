<template>
  <nuxt-link
    :to="banner.url"
    :target="banner.url?.startsWith('/') ? '_self' : '_blank'"
  >
    <q-img
      :src
      :placeholder-src
      :alt
      height="400px"
      fit="cover"
      no-spinner
    >
      <ClientOnly>
        <div
          v-if="banner.content"
          :class="[
            `absolute-${banner.content.position}`,
            `text-${banner.content.align}`,
          ]"
          class="text-banner"
        >
          <LazyStrapiBlocks :nodes="banner.content.body" />
        </div>
        <div
          v-if="banner.cta"
          :class="[`absolute-${banner.cta.position}`]"
          class="transparent"
        >
          <lazy-q-btn v-bind="ctaBtnProps" />
        </div>
      </ClientOnly>
    </q-img>
  </nuxt-link>
</template>

<script lang="ts" setup>
import type { QBtnProps } from 'quasar';
import type { Banner } from '~/types/strapi';

const props = defineProps({
  banner: {
    type: Object as PropType<Banner>,
    required: true,
  },
  small: {
    type: Boolean,
  },
});

const ctaBtnProps = computed(() => {
  const btnProps: QBtnProps = {
    label: props.banner.cta?.label ?? 'Go',
    color: props.banner.cta?.color ?? 'primary',
    size: 'xl',
    padding: 'sm lg',
    square: true,
  };

  const url = props.banner.cta?.to ?? props.banner.url;

  if (!url) {
    return props;
  }

  const relative = url.startsWith('/');

  btnProps.target = relative ? '_self' : '_blank';

  if (relative) {
    btnProps.to = url;
  }
  else {
    btnProps.href = url;
  }

  return btnProps;
});

const imgUrl = computed(() => {
  const defaultUrl = props.banner.large.data.attributes.url;

  if (import.meta.server) {
    return defaultUrl;
  }

  if (!props.banner.small) {
    return defaultUrl;
  }

  return props.small ? props.banner.small.data.attributes.url : defaultUrl;
});

const src = computed(() => useCloudflareImage(imgUrl, { f: 'auto', h: 400 }));

const placeholderSrc = computed(() => useCloudflareImage(imgUrl, { f: 'auto', blur: 100, h: 400 }));

const alt = computed(() => props.banner.large.data.attributes.alternativeText
  ?? props.banner.small?.data.attributes.alternativeText
  ?? props.banner.name);
</script>
